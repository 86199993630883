import { useMutation } from "react-query"
import { useContext, useEffect, useState } from "react"
import moment, { invalid } from "moment"
import "moment/locale/nb"
import { NumericFormat } from "react-number-format"
import { UserContext } from "../../ProductionPlan/Components/UserContext"
import { useForm } from "react-hook-form"
import { patchApi } from "../../../Common/WebApi-utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { GetProfile } from "../../ProductionPlan/Components/ReactQueries"
import SavedCensusButton from "../Buttons/SavedCensusButton"
import NotificationAlert from "../../../Graphics/NotificationAlert"

export type saveStockCensus = {
  Id: string
  Quantity: number
  PercentClass1: number
  QuantityClass1: number
  CensusStatus: number
  Comment: string
  SubmittedDate: string
}

export type saveAreaCensus = {
  Id: string
  Quantity: number
  Comment: string
  CensusStatus: number
  SubmittedDate: string
}

const CensusesRegistration = ({ Censuses, i, today }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [censusType, setCensusType] = useState(Censuses?.censusType)
  const [quantity, setQuantity] = useState(Censuses?.quantity)
  const [percentClass, setPercentClass] = useState(Censuses?.percentClass1)
  const [quantityClass, setQuantityClass] = useState(Censuses?.quantityClass1)
  const [comment, setComment] = useState(Censuses?.comment)
  const [deadLine, setDeadLine] = useState(new Date(Censuses?.deadLine))
  const [editablePercentClass, setEditablePercentClass] = useState(Censuses?.percentClass1 > 0)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [activeAccount, activeVendor, loadingDone, setLoadingDone, setRefresh] = useContext(UserContext)
  const { handleSubmit } = useForm({
    mode: "onChange",
  })
  moment.locale("nb")

  const SaveCensus = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/Census", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        setRefresh(true)
      },
    }
  )

  useEffect(() => {
    if (quantity > 0 && percentClass > 0) {
      setQuantityClass((quantity * percentClass) / 100)
    } else {
      setQuantityClass(0)
    }
  }, [percentClass, quantity])

  const saveStockCensus = () => {
    const saveCensusObject: saveStockCensus = {
      Id: Censuses?.id,
      Quantity: quantity,
      PercentClass1: percentClass,
      QuantityClass1: quantityClass,
      CensusStatus: 292460002,
      Comment: comment,
      SubmittedDate: new Date(today).toISOString(),
    }
    SaveCensus.mutate(saveCensusObject)
    setLoadingDone(false)
  }

  const saveAreaCensus = () => {
    const saveAreaRegObject: saveAreaCensus = {
      Id: Censuses?.id,
      Quantity: quantity,
      Comment: comment,
      CensusStatus: 292460002,
      SubmittedDate: new Date(today).toISOString(),
    }
    SaveCensus.mutate(saveAreaRegObject)
    setLoadingDone(false)
  }

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <div key={i} className=" flex flex-col border-b-2 pb-8 border-grey ">
        {/* Producer */}
        <div className=" pt-4 mb-8 px-4  ">
          <p className=" text-base uppercase ">{Censuses?.customer?.customerName}</p>
        </div>
        {/* Inputs */}
        <div className=" flex flex-col space-y-4 px-4 ">
          {/* Plan quantity */}
          <div className=" flex flex-col">
            <p className=" text-base font-semibold pb-1 ">Plan 2023</p>
            <div className=" flex flex-row items-center ">
              <NumericFormat
                displayType="text"
                value={Censuses?.prodPlan?.sumQuantity}
                className=" border border-grey rounded-lg w-28 h-10 text-center p-2"
                thousandSeparator=" "
              />
              <p className=" ml-2 "> kg </p>
            </div>
          </div>
          {/* Census type === Stock */}
          {censusType === 292460000 ? (
            <form name="saveCensus" method="patch" onSubmit={handleSubmit(saveStockCensus)}>
              {/* Quantity */}
              <div className=" flex flex-col">
                <div>
                  <p className=" text-base font-semibold ">Mengde</p>
                  <p className=" text-sm pb-3 ">Sams vare i hele tall</p>
                </div>
                <div className=" flex flex-row items-center ">
                  <NumericFormat
                    type="tel"
                    displayType="input"
                    required
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value.replace(/\s/g, "")))}
                    thousandSeparator=" "
                    className=" border border-grey rounded-lg w-28 h-10 text-center p-2"
                  />
                  <p className=" ml-2 "> kg </p>
                </div>
              </div>
              {/* Share class 1  */}
              <div className=" flex flex-col mt-4">
                <p className=" text-base font-semibold ">Andel klasse 1</p>
                <div className=" flex flex-row items-center ">
                  <NumericFormat
                    required
                    readOnly={editablePercentClass}
                    type="tel"
                    displayType="input"
                    thousandSeparator=" "
                    value={percentClass}
                    onChange={(e) => setPercentClass(parseInt(e.target.value.replace(/\s/g, "")))}
                    className=" border border-grey rounded-lg w-28 h-10 text-center p-2"
                  />
                  <p className=" ml-2 "> % </p>
                </div>
              </div>
              {/* Amount class 1 */}
              <div className=" flex flex-col mt-4">
                <p className=" text-base font-semibold ">Mengde klasse 1</p>
                <p className=" text-sm pb-3 ">Kan redigeres</p>
                <div className=" flex flex-row items-center ">
                  <NumericFormat
                    type="tel"
                    displayType="input"
                    required
                    value={quantityClass}
                    thousandSeparator=" "
                    onChange={(e) => setQuantityClass(parseInt(e.target.value.replace(/\s/g, "")))}
                    className=" border border-grey rounded-lg w-28 h-10 text-center p-2"
                  />
                  <p className=" ml-2 "> kg </p>
                </div>
              </div>
              {/* Comment */}
              <div className=" flex flex-col mt-4">
                <p className=" text-base font-semibold pb-3 ">Kommentar</p>
                <textarea
                  maxLength={200}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  className=" border border-grey rounded-lg h-40 max-w-50 min-[900px]:max-w-md "
                />
              </div>
              {/* Button */}
              <>
                {/* If deadLine is bigger than today or if deadLine have no value. Show button */}
                {deadLine >= today || isNaN(deadLine) ? (
                  <div className=" mt-4 mb-2 ">
                    {Censuses?.censusStatus === 292460002 ? (
                      <div>
                        <SavedCensusButton />
                        <p className=" pt-4 text-sm text-grey">
                          Sist lagret {moment(Censuses?.modifiedOn).format("LLL")}
                        </p>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className=" px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0 disabled:bg-disbled disabled:border-disbled disabled:text-white focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500 "
                      >
                        Lagre areal
                      </button>
                    )}
                  </div>
                ) : null}
              </>
            </form>
          ) : (
            <form name="saveArea" method="patch" onSubmit={handleSubmit(saveAreaCensus)}>
              {/* Census type === Area */}
              {/* Quantity */}
              <div className=" flex flex-col">
                <div>
                  <p className=" text-base font-semibold ">Areal</p>
                </div>
                <div className=" flex flex-row items-center ">
                  <NumericFormat
                    type="tel"
                    displayType="input"
                    required
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value.replace(/\s/g, "")))}
                    thousandSeparator=" "
                    className=" border border-grey rounded-lg w-28 h-10 text-center p-2"
                  />
                  <p className=" ml-2 "> dekar </p>
                </div>
              </div>
              {/* Comment */}
              <div className=" flex flex-col mt-4">
                <p className=" text-base font-semibold pb-3 ">Kommentar</p>
                <textarea
                  maxLength={200}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  className=" border border-grey rounded-lg h-40 max-w-50 min-[900px]:max-w-md "
                />
              </div>
              {/* Button */}
              <>
                {/* If deadLine is bigger than today or if deadLine have no value. Show button */}
                {deadLine >= today || isNaN(deadLine) ? (
                  <div className=" mt-4 mb-2 ">
                    {Censuses?.censusStatus === 292460002 ? (
                      <div>
                        <SavedCensusButton />
                        <p className=" pt-4 text-sm text-grey">
                          Sist lagret {moment(Censuses?.modifiedOn).format("LLL")}
                        </p>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className=" px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0 disabled:bg-disbled disabled:border-disbled disabled:text-white focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500 "
                      >
                        Lagre areal
                      </button>
                    )}
                  </div>
                ) : null}
              </>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default CensusesRegistration
