import { NumericFormat } from "react-number-format"
import { productUnit } from "../../ProductionPlan/Components/Maps"
import { PenIcon } from "../../../Graphics/IconsMyPage"
import BamaPlansTableLines from "./BamaPlansTableLines"
import React, { useEffect, useState } from "react"

const BamaPlansTableContent = ({ planProduct, weekNumber, activeVendor, setValues }) => {
  const [amount, setAmount] = useState<number>(0)
  //   console.log(amount)

  useEffect(() => {
    const total =
      planProduct?.prodPlan
        ?.map((prodPlan) => prodPlan?.planWeeks?.[0]?.registered)
        ?.reduce((acc, cur) => acc + cur, 0) || 0
    setAmount(total)
  }, [weekNumber, planProduct, activeVendor])

  return (
    //Add weekNumber to key to render new table each time week is changed
    <React.Fragment key={planProduct.id + weekNumber + activeVendor}>
      {/* white space divider */}
      {/* <div className="bg-white h-4"></div> */}
      <tr className=" bg-blue bg-opacity-20 text-xs sm:text-base whitespace-nowrap">
        <th className=" pl-1 sm:pl-5 py-4 text-left font-semibold border-b border-grey uppercase">
          {planProduct?.planProductName}
        </th>
        {/* This year */}
        <td className="  text-center border-b border-grey ">
          <NumericFormat
            displayType="text"
            value={planProduct?.prodPlan
              ?.map((prodPlan) => prodPlan?.planWeeks?.[0]?.planWeekQuantity)
              ?.reduce((acc, cur) => acc + cur)}
            thousandSeparator={" "}
          />{" "}
          {productUnit.get(planProduct?.prodPlan[0]?.planProduct?.planProductUnit)}
        </td>
        {/* Registered */}
        <td className="  text-center border-b border-grey ">
          <NumericFormat
            className=" text-center text-xs h-8 w-14 sm:text-base sm:w-28 mr-2"
            displayType="input"
            value={amount}
            thousandSeparator={" "}
            onValueChange={(e) => {
              setAmount(e.floatValue)
              setValues.setShowSaveButton(true)
            }}
          />
          {productUnit.get(planProduct?.prodPlan[0]?.planProduct?.planProductUnit)}
        </td>
        {/* Change capacity */}
        <td className="table-cell bg-white border-none">
          <button
            onClick={() => {
              setValues.setShowModal(true)
              setValues.setPlanProductName(planProduct?.planProductName)
              setValues.setPlanProductId(planProduct?.id)
            }}
            className=" mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <PenIcon />
            <span className=" hidden lg:flex sm:ml-1 ">Rediger kapasitet</span>
          </button>
        </td>
      </tr>
      {planProduct?.prodPlan?.map((prodPlan) => {
        //Get the total quantity of the plan to calculate the percentage and use on registered plans
        const totalQuantity = planProduct?.prodPlan
          ?.map((prodPlan) => prodPlan?.planWeeks?.[0]?.planWeekQuantity)
          ?.reduce((acc, cur) => acc + cur)
        return (
          <BamaPlansTableLines
            prodPlan={prodPlan}
            percentage={prodPlan?.planWeeks?.[0]?.planWeekQuantity / totalQuantity}
            amount={amount}
            weekNumber={weekNumber}
          />
        )
      })}
    </React.Fragment>
  )
}

export default BamaPlansTableContent
