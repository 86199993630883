export const productUnit = new Map([
  [292460000, "kg"],
  [292460001, "bt"],
  [292460002, "st"],
  [292460003, "pt"],
  [292460004, "pk"],
])

export const month = new Map<string, string>([
  ["January", "Januar"],
  ["February", "Februar"],
  ["March", "Mars"],
  ["April", "April"],
  ["May", "Mai"],
  ["June", "Juni"],
  ["July", "Juli"],
  ["August", "August"],
  ["September", "September"],
  ["October", "Oktober"],
  ["November", "November"],
  ["December", "Desember"],
])

export const year = new Map<string, number>([
  ["2022", 292460000],
  ["2022-2023", 292460001],
  ["2023", 292460002],
  ["2023-2024", 292460003],
  ["2024", 292460004],
  ["2024-2025", 292460005],
  ["2025", 292460006],
  ["2025-2026", 292460007],
  ["2026", 292460008],
  ["2026-2027", 292460009],
  ["2027", 292460010],
  ["2027-2028", 292460011],
  ["2028", 292460012],
  ["2028-2029", 292460013],
  ["2029", 292460014],
  ["2029-2030", 292460015],
  ["2030", 292460016],
  ["2030-2031", 292460017],
  ["2031", 292460018],
  ["2031-2032", 292460019],
  ["2032", 292460020],
  ["2032-2033", 292460021],
  ["2033", 292460022],
])

export const yearString = new Map<number, string>([
  [292460000, "2022"],
  [292460001, "2022-2023"],
  [292460002, "2023"],
  [292460003, "2023-2024"],
  [292460004, "2024"],
  [292460005, "2024-2025"],
  [292460006, "2025"],
  [292460007, "2025-2026"],
  [292460008, "2026"],
  [292460009, "2026-2027"],
  [292460010, "2027"],
  [292460011, "2027-2028"],
  [292460012, "2028"],
  [292460013, "2028-2029"],
  [292460014, "2029"],
  [292460015, "2029-2030"],
  [292460016, "2030"],
  [292460017, "2030-2031"],
  [292460018, "2031"],
  [292460019, "2031-2032"],
  [292460020, "2032"],
  [292460021, "2032-2033"],
  [292460022, "2033"],
])

export const applicationStatus = new Map([
  [292460000, "Under behandling"],
  [292460001, "Under behandling"],
  [292460002, "Godkjent"],
  [292460003, "Avvist"],
  [292460004, "Avsluttet"],
])

export const applicationType = new Map([
  [292460000, "Dispensasjonssøknad"],
  [292460001, "Permisjonssøknad"],
])

export const prodplanstatus = new Map([
  [292460002, "Klar til godkjenning"],
  [292460003, "Godkjent"],
  [292460004, "Avvist"],
])
