import React, { useContext, useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { productUnit } from "../../ProductionPlan/Components/Maps"
import { UserContext } from "../../ProductionPlan/Components/UserContext"

const BamaPlansTableLines = ({ prodPlan, percentage, amount, weekNumber }) => {
  const [registeredValue, setRegisteredValue] = useState<number>(0)
  const [registeredArray, setRegisteredArray] = useContext(UserContext)
  console.log(registeredValue)
  // console.log(Number(amount))
  // console.log(percentage)
  // console.log(amount * percentage)
  console.log(registeredArray)

  useEffect(() => {
    setRegisteredValue(Math.round(amount * percentage) || 0)
  }, [amount])

  useEffect(() => {
    setRegisteredArray((prevState) =>
      prevState.map((obj) => (obj.prodPlanId === prodPlan.id ? { ...obj, amount: registeredValue } : obj))
    )
  }, [registeredValue])

  // console.log(
  //   registeredPlan?.data?.find((regPlan) => regPlan?.prodPlanId === prodPlan?.planProduct?.id)?.amount * percentage
  // )
  return (
    <React.Fragment key={weekNumber + prodPlan.id}>
      <tr className=" text-xs sm:text-base  ">
        <td className=" pl-2 sm:pl-10 py-4 border-b border-grey uppercase">{prodPlan?.customer?.customerName}</td>
        {/* Plan */}
        <td className=" table-cell border-b border-grey text-center text-ghGreen-600 ">
          <NumericFormat
            displayType="text"
            value={prodPlan?.planWeeks?.[0]?.planWeekQuantity}
            thousandSeparator={" "}
          />{" "}
          {productUnit.get(prodPlan?.planProduct?.planProductUnit)}
        </td>
        {/* Registered */}
        <td className="  text-center  border-b border-grey ">
          <NumericFormat displayType="text" value={registeredValue} thousandSeparator={" "} decimalScale={0} />{" "}
          {productUnit.get(prodPlan?.planProduct?.planProductUnit)}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default BamaPlansTableLines
